











import { Component, Vue, Prop } from 'vue-property-decorator'
import { Color as MEMUColor, Copy as MEMUCopy } from '../../shared/MEMU'

@Component
export default class Blade1 extends Vue {
  @Prop() readonly isVisible!: boolean
  @Prop() readonly isMobile!: boolean
  @Prop() readonly colorTheme!: MEMUColor.ColorTheme
  
  private copyStr = MEMUCopy.blade1String

  // ---------------
  
  // private boundWindowResizeFunc: (() => void) | null = null
  // private boundScrollFunc: ((evt: Event) => void) | null = null

  created () {
    // this.boundWindowResizeFunc = this.windowResized.bind(this)
    // this.boundScrollFunc = this.handleScroll.bind(this)

    // window.addEventListener('resize', this.boundWindowResizeFunc)
    // window.addEventListener('scroll', this.boundScrollFunc)
  }
  mounted () {
    // console.log('mounted')
    // console.log('window.innerHeight: ', window.innerHeight)
  }
  destroyed () {
    // if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    // if (this.boundScrollFunc) { window.removeEventListener('scroll', this.boundScrollFunc) }
  }

  private resizeTimeout = 0

  // eslint-disable-next-line
  windowResized() {
    // if (window.innerHeight == this.lastWindowHeight) { return }
    // console.log('????::windowResized, deviceIsMobile: ', this.deviceIsMobile)

    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.windowResizeDebounced.bind(this), 900)
  }
  windowResizeDebounced() {
    console.log('????::windowResizeDebounced')
  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    console.log('????::handleScroll')
    
  }

}
