

































import { Component, Vue, Prop } from 'vue-property-decorator'
import MemuLogo from '../../shared/assets/memu-logo.svg'

import { ChannelConfig } from '../../shared/channel-configs/ChannelConfig'

// import variables from '../styles/variables.scss'
import { Color as MEMUColor } from '../../shared/MEMU'
import { Copy as MEMUCopy } from '../../shared/MEMU'


@Component({
  components: { MemuLogo }
})
export default class Hero extends Vue {
  @Prop() readonly channelList!: ChannelConfig[]
  @Prop() readonly isVisible!: boolean
  @Prop() readonly isMobile!: boolean
  @Prop() readonly colorTheme!: MEMUColor.ColorTheme

  get channelListFiltered() {
    return this.channelList.filter((el) => el.showInChannelList);
  }

  channelListIsOpen = false
  bylineStr = MEMUCopy.byline

  private lastScrollY = 0
  
  private boundWindowResizeFunc: (() => void) | null = null
  private boundScrollFunc: ((evt: Event) => void) | null = null
  // private boundWindowFocusFunc: ((evt: FocusEvent) => void) | null = null
  // private boundWindowBlurFunc: ((evt: FocusEvent) => void) | null = null

  created () {
    

    this.boundWindowResizeFunc = this.windowResized.bind(this)
    this.boundScrollFunc = this.handleScroll.bind(this)
    // this.boundWindowFocusFunc = this.windowFocusOccurred.bind(this)
    // this.boundWindowBlurFunc = this.windowBlurOccurred.bind(this)

    window.addEventListener('resize', this.boundWindowResizeFunc)
    window.addEventListener('scroll', this.boundScrollFunc)
    // window.addEventListener('focus', this.boundWindowFocusFunc)
    // window.addEventListener('blur', this.boundWindowBlurFunc)
  }
  mounted () {
    // console.log('mounted')
    // console.log('window.innerHeight: ', window.innerHeight)
    // console.log('colTheme', this.colorTheme)
  }
  destroyed () {
    if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    if (this.boundScrollFunc) { window.removeEventListener('scroll', this.boundScrollFunc) }
    // if (this.boundWindowFocusFunc) { window.removeEventListener('focus', this.boundWindowFocusFunc) }
    // if (this.boundWindowBlurFunc) { window.removeEventListener('blur', this.boundWindowBlurFunc) }
    
  }

  exploreChannelsBtnClicked() {
    this.channelListIsOpen = !this.channelListIsOpen;

    if (this.channelListIsOpen) {
      const channelListEl = this.$refs.channelList as Element
      this.$emit('channel-list-height-change-event', 
                  channelListEl.scrollHeight);
    } else {
      this.$emit('channel-list-height-change-event', 0);
    }
  }

  private resizeTimeout = 0

  // eslint-disable-next-line
  windowResized() {
    // if (window.innerHeight == this.lastWindowHeight) { return }
    // console.log('Hero::windowResized, deviceIsMobile: ', this.deviceIsMobile)

    if (this.channelListIsOpen) {
      const channelListEl = this.$refs.channelList as Element
      this.$emit('channel-list-height-change-event', 
                  channelListEl.scrollHeight);
    }
    
    // - - - - - - - - - -
    if (this.isMobile) { return }

    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.windowResizeDebounced.bind(this), 900)
  }
  windowResizeDebounced() {
    // console.log('Hero::windowResizeDebounced')
  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    if (window.scrollY == this.lastScrollY) { return }
    // console.log('Hero::handleScroll')
    
    this.lastScrollY = window.window.scrollY
  }

  downButtonClicked() {
    // console.log('downButtonClicked')
    this.$emit('scroll-down-clicked')
  }

}
