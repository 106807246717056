










































import firebase from 'firebase/app'
import 'firebase/analytics'

import { default as channels } from '../shared/channel-configs/Channels'

import { Component, Vue, Watch } from 'vue-property-decorator'
import Hero from '@/views/Hero.vue'
import Blade1 from '@/views/Blade1.vue'
import Blade2 from '@/views/Blade2.vue'
import Blade3 from '@/views/Blade3.vue'
import BladeFooter from '@/views/BladeFooter.vue'

import isMobile from "is-mobile"
import { ScrollObserver, ScrollObserverDelegate } from '../shared/ScrollObserver'
// import { ScrollLock } from './ScrollLock'

// import MemuLongShapes from '../shared/assets/memu-long-shapes.svg'

// import { Component as ComponentType } from 'vue'

// https://stackoverflow.com/questions/30357634/how-do-i-use-namespaces-with-typescript-external-modules
// import * as MEMU from '../shared/MEMU'
import { Color as MEMUColor } from '../shared/MEMU'

// ------------------------------------------------

const isMob = isMobile()
// console.log('isMobile:', isMob)

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFIgBJASPdfyYcemm5ixdBmjFfHYo2YCM",
  authDomain: "uv-memu.firebaseapp.com",
  projectId: "uv-memu",
  storageBucket: "uv-memu.appspot.com",
  messagingSenderId: "851534554818",
  appId: "1:851534554818:web:592a1edb5730d9c1d5fd5b",
  measurementId: "G-ZJ46CTQ79F"
}

// ------------------------------------------------

@Component({
  components: { Hero, Blade1, Blade2, Blade3, BladeFooter }
})
export default class App extends Vue implements ScrollObserverDelegate {

  firebaseApp: firebase.app.App | null = null

  isMobileDevice = isMob
  heroIsVisible = true
  colorTheme = MEMUColor.getRandomColorTheme()
  channelList = channels

  // -----------------------
  static framerate = 60
  static framerateFraction = App.framerate * 0.001
  private updateInterval: number | null = null
  private lastWindowHeight = 0
  private lastScrollY = 0
  private largeScrollTime = Date.now()
  // ---------------
  // private targetXPercent = -10
  
  memuShapesXPercent = -47
  memuShapesColorChangeYPos = 0
  memuShapesMaxYPos = 1400
  expandedChannelListHeight = 0
  
  targetYTranslate = 0
  memuShapesY = 0
  @Watch('memuShapesY', { immediate: true }) 
  memuShapesYChanged(newValue: number, oldVal: number) {
    if (oldVal < this.memuShapesColorChangeYPos && newValue >= this.memuShapesColorChangeYPos) {
      this.memuShapesBGImgBase64 = this.memuShapesSVGStr2
    } else if (oldVal > this.memuShapesColorChangeYPos && newValue <= this.memuShapesColorChangeYPos) {
      this.memuShapesBGImgBase64 = this.memuShapesSVGStr1
    }
    // memuShapesY < memuShapesColorChangeYPos
  }

  private memuShapesSVGStr1 = App.getMemuShapesSVGStr(this.colorTheme.colors[1].hexString)
  private memuShapesSVGStr2 = App.getMemuShapesSVGStr(this.colorTheme.colors[2].hexString)
  memuShapesBGImgBase64 = this.memuShapesSVGStr1

  private static getMemuShapesSVGStr(fillStr: string) {
    return 'url("data:image/svg+xml;base64,' + 
    window.btoa('<svg viewBox="0 0 2051 610" fill="#' + fillStr + '" xmlns="http://www.w3.org/2000/svg">\
                  <path d="M327.974 608.282C321.756 606.012 314.846 604.649 309.088 601.471C294.348 592.843 286.748 579.674 286.748 562.646C286.748 442.766 286.748 323.113 286.748 203.233C286.748 164.635 320.374 141.93 353.079 145.336C377.953 147.834 394.306 161.683 402.828 184.388C440.6 285.196 478.372 386.231 516.144 487.04C523.284 506.339 530.654 525.637 537.794 544.936C548.388 573.317 531.805 601.925 501.634 607.374C500.943 607.374 500.482 607.828 499.791 608.055C442.442 608.282 385.323 608.282 327.974 608.282Z"/>\
                  <path d="M651.615 1.22725C657.567 2.59109 663.518 3.27291 669.241 5.09137C698.313 14.411 718 41.4607 718 71.6926C718 227.171 718 382.649 718 538.128C718 573.588 689.157 604.729 654.362 607.684C613.157 611.321 581.567 581.998 576.989 548.129C576.531 544.72 576.302 541.31 576.302 537.673C576.302 382.195 576.302 226.944 576.302 71.4653C576.302 35.5507 603.314 5.54598 639.253 1.68175C640.169 1.68175 641.314 1.2273 642.229 0.999997C645.434 1.2273 648.639 1.22725 651.615 1.22725Z"/>\
                  <path d="M74.1385 608.817C68.1789 607.452 62.2194 606.77 56.489 604.951C22.7944 594.488 0.101995 564.011 0.101997 528.984C-0.127215 440.508 0.102005 352.032 0.102008 263.556C0.10201 233.988 16.3763 210.334 43.1944 199.417C70.4711 188.499 100.957 194.413 120.44 214.2C127.546 221.479 132.588 230.122 136.256 239.674C173.847 340.887 211.439 442.1 249.03 543.313C260.032 572.881 241.695 603.131 210.522 608.362C209.834 608.362 209.376 608.817 208.688 609.044C163.762 608.817 118.835 608.817 74.1385 608.817Z"/>\
                  <path d="M1170.02 607.347C1161.74 604.852 1152.55 603.491 1144.96 599.635C1121.96 587.839 1109.31 568.33 1109.08 542.696C1108.62 486.892 1108.62 431.089 1109.08 375.512C1109.31 331.277 1141.74 295.209 1186.12 289.084C1218.09 284.774 1245.69 293.167 1268 316.986C1275.82 325.379 1281.34 335.133 1285.48 345.795C1311.47 415.21 1337.45 484.851 1363.44 554.266C1372.18 577.858 1357.69 602.357 1332.62 606.894C1331.93 606.894 1331.47 607.347 1330.78 607.574C1277.2 607.347 1223.61 607.347 1170.02 607.347Z"/>\
                  <path d="M753.447 405.978C753.447 360.964 752.987 316.178 753.677 271.164C754.137 235.926 784.487 205.689 820.125 204.098C857.143 202.279 887.723 225.923 894.85 261.843C895.77 266.163 896 270.482 896 274.802C896 362.329 896 449.628 896 537.155C896 573.529 868.409 604.221 833.001 607.631C794.833 611.268 761.495 586.943 754.597 550.113C753.677 545.339 753.447 540.11 753.447 535.109C753.447 492.368 753.447 449.173 753.447 405.978Z"/>\
                  <path d="M1073.67 507.982C1073.67 520.249 1074.36 532.289 1073.44 544.556C1071.14 582.04 1036.88 611.345 998.93 608.846C963.973 606.575 937.065 582.267 932.005 547.964C931.775 545.919 931.545 543.875 931.545 541.83C931.545 518.659 930.855 495.26 931.775 472.088C933.155 439.148 960.063 411.433 993.181 407.571C1030.67 403.028 1064.02 425.972 1072.29 462.32C1073.67 468.226 1073.67 474.587 1073.9 480.721C1074.13 489.808 1073.9 498.895 1073.67 507.982C1073.9 507.982 1073.9 507.982 1073.67 507.982Z"/>\
                  <path d="M1852.21 323C1893.49 323 1934.77 323 1976.28 323C2000.36 323 2015.72 338.442 2015.95 362.287C2016.64 399.984 2009.99 436.319 1993.25 470.383C1957.01 543.507 1898.53 588.244 1817.8 604.14C1799.92 607.547 1781.57 609.136 1763.45 607.092C1726.76 603.005 1694.88 572.574 1689.38 536.24C1688.46 530.335 1688 524.204 1688 518.299C1688 466.295 1688 414.064 1688 362.06C1688 343.438 1698.55 328.904 1715.75 324.362C1719.42 323.454 1723.32 323 1727.22 323C1768.73 323 1810.47 323 1852.21 323Z"/>\
                  <path d="M1474.9 607.817C1468.94 606.452 1462.98 605.77 1457.25 603.951C1423.55 593.488 1400.86 563.011 1400.86 527.984C1400.63 439.508 1400.86 351.032 1400.86 262.556C1400.86 232.988 1417.13 209.334 1443.95 198.417C1471.23 187.499 1501.71 193.413 1521.2 213.2C1528.3 220.479 1533.35 229.122 1537.01 238.674C1574.6 339.887 1612.2 441.1 1649.79 542.313C1660.79 571.881 1642.45 602.131 1611.28 607.362C1610.59 607.362 1610.13 607.817 1609.45 608.044C1564.52 607.817 1519.59 607.817 1474.9 607.817Z"/>\
                </svg>')
  }
  // ---------------

  private boundWindowResizeFunc: (() => void) | null = null
  private boundScrollFunc: ((evt: Event) => void) | null = null

  // ---------------

  private recalculateMemuShapeBreakpoints() {
    const blade2 = this.$refs.blade2 as Vue
    const blade3 = this.$refs.blade3 as Vue
    const shapes = this.$refs.memushapes as HTMLDivElement
    if (blade2 == null || blade3 == null || shapes == null) { return }
    const blade2El = blade2.$el as HTMLElement
    const blade3El = blade3.$el as HTMLElement
    const blade2Rect = blade2El.getBoundingClientRect()
    const blade3Rect = blade3El.getBoundingClientRect()

    const blade2YPos = blade2Rect.top + window.scrollY
    const shapesTop = parseInt(shapes.style.top)

    const foo = (blade2Rect.height - shapes.getBoundingClientRect().height) * 0.5
    this.memuShapesColorChangeYPos = blade2YPos + foo - shapesTop
    // console.log(this.memuShapesColorChangeYPos)

    // - - - - - - - - - - -

    const blade3YPos = blade3Rect.top + window.scrollY
    const bar = blade3Rect.height * 0.05
    // console.log(blade3YPos)
    this.memuShapesMaxYPos = blade3YPos + bar - shapesTop

    this.updateMemuShapesYTarget()
  }

  heroChannelListExpansionChanged(height: number) {
    this.expandedChannelListHeight = height
    this.updateMemuShapesYTarget()
  }

  // ---------------------------------------------------
  
  //  #     # ######  ######     #    ####### ####### 
  //  #     # #     # #     #   # #      #    #       
  //  #     # #     # #     #  #   #     #    #       
  //  #     # ######  #     # #     #    #    #####   
  //  #     # #       #     # #######    #    #       
  //  #     # #       #     # #     #    #    #       
  //   #####  #       ######  #     #    #    ####### 
                                                 

  // https://codepen.io/ma77os/pen/KGIEh
  lerp (start: number, end: number, amt: number) {
      const m = (1-amt)
      return m*start+amt*end
  }

  updateCallback() {
    const now = Date.now()
    const largeScrollDelta = now - this.largeScrollTime;
    const scrollDamping = largeScrollDelta > 150 ? 1 : (largeScrollDelta+150) / 300

    const amount = 0.8 * App.framerateFraction * scrollDamping

    const newYTrans = this.lerp (this.memuShapesY, this.targetYTranslate, amount)
    this.memuShapesY = Math.round((newYTrans + Number.EPSILON) * 100) / 100

    const newXPercent = -47 + 6 * Math.sin( this.memuShapesY * 0.003 )

    this.memuShapesXPercent = Math.round((newXPercent + Number.EPSILON) * 100) / 100
  }

  startUpdateLoop() {
    if (this.updateInterval != null) { return }
    // console.log('startUpdateLoop')
    this.updateInterval = setInterval (this.updateCallback.bind(this),
                        1000 / App.framerate)
  }
  stopUpdateLoop() {
    if (this.updateInterval == null) { return }
    // console.log('stopUpdateLoop')
      
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }
  // ---------------

  created() {
    this.lastWindowHeight = window.innerHeight

    this.boundWindowResizeFunc = this.windowResized.bind(this)
    this.boundScrollFunc = this.handleScroll.bind(this)
    window.addEventListener('resize', this.boundWindowResizeFunc)
    window.addEventListener('scroll', this.boundScrollFunc)
    
    document.addEventListener('DOMContentLoaded', this.domContentLoaded.bind(this))
    //window.onpopstate = this.onPopState
  }

  destroyed() {
    if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    if (this.boundScrollFunc) { window.removeEventListener('scroll', this.boundScrollFunc) }

    if (this.updateInterval == null) { return }
    clearInterval(this.updateInterval)
  }

  domContentLoaded() {
    // console.log('app.vue::domContentLoaded')

    // const isContentURL = tabDataDesktopArray
    //   .findIndex((tabDat: TabData) => tabDat.route == document.location.pathname) >= 0

    // The url path is /projects, /about or /team
    // if (isContentURL) {
    //   this.scrollFullpageDown()
    // }

    // =============================
    // INIT ANALYTICS
    if (this.firebaseApp == null) {
      this.firebaseApp = firebase.initializeApp(firebaseConfig)
    }
    if (this.firebaseApp == null) {
      // console.error('Firebase App NOT initialised')
      return
    }

    this.firebaseApp.analytics()

    // this.firebaseFunctions = this.firebaseApp.functions('australia-southeast1')

    this.recalculateMemuShapeBreakpoints()
  }

  // ---------------------------------

  private resizeTimeout = 0

  // eslint-disable-next-line
  windowResized() {
    // if (window.innerHeight == this.lastWindowHeight) { return }
    // console.log('Hero::windowResized, deviceIsMobile: ', this.deviceIsMobile)
    
    this.recalculateMemuShapeBreakpoints()
    // this.memuShapesColorChangeYPos = App.memuShapesColorChangeYPos

    // - - - - - - - - - -
    this.lastWindowHeight = window.innerHeight
    if (this.isMobileDevice) { return }

    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.windowResizeDebounced.bind(this), 900)
  }
  windowResizeDebounced() {
    // console.log('Hero::windowResizeDebounced')
  }

  updateMemuShapesYTarget() {
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight;

    
    const bladeHero = this.$refs.heroBlade as Vue
    const bladeHeroEl = bladeHero.$el as HTMLElement
    const blade3 = this.$refs.blade3 as Vue
    const blade3El = blade3.$el as HTMLElement
    const bladeFooter = this.$refs.bladeFooter as Vue
    const bladeFooterEl = bladeFooter.$el as HTMLElement

    const moveRange = maxScroll - (this.expandedChannelListHeight 
                                  + bladeFooterEl.scrollHeight 
                                  + (0.6 * blade3El.scrollHeight));
    const percent = Math.min(1, Math.max(0, window.scrollY - (this.expandedChannelListHeight)) / moveRange);

    this.targetYTranslate = percent * moveRange 
                            + this.expandedChannelListHeight + 64 
                            + (bladeHeroEl.scrollHeight * 0.1);
  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    if (window.scrollY == this.lastScrollY) { return }
    // console.log('Hero::handleScroll')
    const delta = window.scrollY - this.lastScrollY
    if (delta > 10) { this.largeScrollTime = Date.now() }
    // console.log(delta)

    // document.getElementById()

    this.recalculateMemuShapeBreakpoints()
    this.updateMemuShapesYTarget()
    
    this.lastScrollY = window.window.scrollY
  }
  // ---------------------------------

  mounted() {
    new ScrollObserver(this, ['hero', 'blade-1', 'blade-2', 'blade-3', 'blade-footer'])

    this.recalculateMemuShapeBreakpoints()

    setTimeout(() => {
      this.startUpdateLoop()
    }, 300)
  }

  // ==============================

  interactionAssetsFinishedLoading() {
    // console.log('interactionAssetsFinishedLoading')

    // document.body.classList.add('uv-finished-loading')
  }

  interactionModeChangedState() { // interactionIsActive: boolean
    if (!this.isMobileDevice) { return }
    // console.log('app.vue interactionModeChangedState', interactionIsActive)
    // this.lockScrollToHero = interactionIsActive
  }

  // ==============================
  // ScrollObserverDelegate

  scrollObserverSectionChanged(sectionID: string) {
    // console.log('scrollObserverSectionChanged', sectionID)

    this.heroIsVisible = (sectionID == 'hero')

  }

  // ==============================

  // eslint-disable-next-line
  scrollToContentMobile(topScroll?: number) {
    // const scrollThresh = topScroll ?? StickyHeaderMobile.scrollThreshold
    // if (window.scrollY < scrollThresh) {
    //   window.scroll({
    //     top: scrollThresh,
    //     left: 0, 
    //     behavior: 'smooth'
    //   })
    // }
  }

  // ======================================================================
  // ======================================================================

  //   #####  ####### #     # #       ####### 
  //  #     #    #     #   #  #       #       
  //  #          #      # #   #       #       
  //   #####     #       #    #       #####   
  //        #    #       #    #       #       
  //  #     #    #       #    #       #       
  //   #####     #       #    ####### ####### 

  // VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

}

