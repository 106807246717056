import { ChannelConfig } from "./ChannelConfig"
import { Vector3 } from 'three/src/math/Vector3'

const private02Channel: ChannelConfig = {
    showInChannelList: true,
    passwordProtected: true,
    id: "private-02",
    
    tickerSeparator: "by",

    // The data below is stored in Firebase
    streamURL: "",
    streamURLDev: "",
    metadataURL: "",

    name: "private 2",
    channelListName: "PRIVATE CHANNEL 2",
    urlSlug: "private-02",
    attributionDescription: "This MEMU channel is using AI to generate music, based on individual artist contributions.",

    colorsGradient2: [ 
        new Vector3(238/255, 1, 1), 
        new Vector3(166/255, 155/255, 206/255)
    ]
}

export default private02Channel