import { ChannelConfig } from "./ChannelConfig"
import { Vector3 } from 'three/src/math/Vector3'

const bestOfChannel: ChannelConfig = {
    showInChannelList: true,
    passwordProtected: false,
    id: "best-of",
    
    tickerSeparator: "by",
    streamURL: "https://memustream2.live:8443/uv",
    streamURLDev: "https://memustream2.live:8443/uv",
    metadataURL: "https://memustream2.live/memu/meta.json",

    name: "Best Of",
    channelListName: "BEST OF",
    urlSlug: "best-of",
    attributionDescription: "...",
    colorsGradient2: [ 
        new Vector3(1, 225/255, 156/255),       // RGB: (255, 225, 156)
        new Vector3(250/255, 247/255, 210/255)  // RGB: (250, 247, 210)
    ]
}

export default bestOfChannel