import { ChannelConfig } from "./ChannelConfig"
import { Vector3 } from 'three/src/math/Vector3'

const defaultChannel: ChannelConfig = {
    showInChannelList: true,
    passwordProtected: false,
    id: "default",

    tickerSeparator: "by",
    streamURL: "https://memustream3.live:8443/lofi_uma",
    streamURLDev: "https://memustream3.live:8443/lofi_uma", //"http://139.180.166.143:8000/spin",
    metadataURL: "https://memustream3.live/memu/meta.json",

    name: "lo-fi",
    channelListName: "LOFI",

    attributionDescription: "This MEMU channel is using AI to generate music, based on individual artist contributions.",
    colorsGradient2: [ 
        new Vector3(238/255, 1, 1), 
        new Vector3(166/255, 155/255, 206/255)
    ]
}

export default defaultChannel