export namespace Copy {
    export const byline = "AI Powered Music Engine"

    export const blade1String = "A collaborative<br>music engine for a<br>new generation of<br>music makers"

    export const blade2String = "MEMU is a new era in music production, powered by AI. It takes small catchy bites of music and turns them into songs and suggestions, just like a meme.\
    <br>MEMU’s prolific production engine generates musical ideas for use in DJ mixes, songwriting, streaming, gaming and more."

    // export const blade2String = "MEMU is a new era in music production, powered by AI. memu is meme music -  it takes small catchy bites of music and turns them into songs and suggestions.\
    // <br>Memu’s prolific production engine generates musical ideas for use in DJ mixes, songwriting, streaming, gaming and more.\
    // <br><br>MEMU has an evolving ability to be customised and scaled. With a fully trackable database, which can integrate financial systems, providing creators with future revenue streams.\
    // <br><br>Use memu to spark creative ideas and enhance music creation."
    
    // https://www.aisongcontest.com/participants-shuffle
    export const eurovisionArtists = [
        'H:Ai:N',
        'A/S (Artificial Spirit)',
        'BeatWire',
        'Metacreation Lab',
        'Beatroots AI',
        'Never Before Heard Sounds',
        'Seoul Wave Factory',
        'Lovelace & The Machines',
        'Menara, Gonimix y Andino',
        'Symbiotic Sounds',
        'Pineapple',
        'Transatlantic Waves',
        'Rubato Lab',
        'uuav',
        'HfMT Hamburg',
        'Smorgasborg',
        'Diwas ft. CHEPANG, Kutumba, and Hari Maharjan Project',
        'Musicians Meeseeks',
        'Jason Brancazio',
        'Nous sommes Whim Therapy',
        'Thunderboom Records',
        'ARTAX',
        'TridecatoNICS',
        'STHLM',
        'Blue Seeds',
        'AIMCAT',
        'SOUND OBSESSED',
        'Nobel Yoo',
        'Battery-operated',
        'Gulluni x Algomus',
        'Zillion Inch Keytar',
        'AUDOIR',
        'The elephants and the',
        'Dadabots',
        'Genetic Scale',
        '5Seven Records',
        'UUrythmicArt',
        'M.O.G.I.I.7.E.D.'
    ]
}