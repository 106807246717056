export namespace Color {
    export interface ColorValues {
        r: number;
        g: number;
        b: number;
    }

    // -------------------------------------------------------------

    // -------------------------------------------------------------
    export interface ColorData {
        name: string;           // Human readable name of the colour
        hexString: string;
        rgbs: ColorValues;
        friends: Array<string>;
    }

    export const colors = new Map<string, ColorData>([
        ['yellow', {
            name: 'yellow',
            hexString: 'DBE253',
            rgbs: { r: 0.8588235294, g: 0.8862745098, b: 0.3254901961 },
            friends: ['orange', 'purple', 'violet', 'cyan']
        }],
        ['purple', {
            name: 'purple',
            hexString: '6252A2',
            rgbs: { r: 0.3843137255, g: 0.3215686275, b: 0.6352941176 },
            friends: ['orange', 'bright orange', 'soft yellow', 'yellow']
        }],
        ['violet', {
            name: 'violet',
            hexString: 'C6B0D5',
            rgbs: { r: 0.7764705882, g: 0.6901960784, b: 0.8352941176 },
            friends: ['green', 'bright orange', 'orange', 'cyan']
        }],
        ['orange', {
            name: 'orange',
            hexString: 'EA882F',
            rgbs: { r: 0.9176470588, g: 0.5333333333, b: 0.1843137255 },
            friends: ['soft yellow', 'purple', 'violet', 'pink']
        }],
        ['pink', {
            name: 'pink',
            hexString: 'EAD0CF',
            rgbs: { r: 0.9176470588, g: 0.8156862745, b: 0.8117647059 },
            friends: ['green', 'bright orange', 'orange', 'purple']
        }],
        ['green', {
            name: 'green',
            hexString: '72833F', // 114, 131, 63
            rgbs: { r: 0.4470588235, g: 0.5137254902, b: 0.2470588235 },
            friends: ['soft yellow', 'violet', 'pink', 'orange']
        }],
        ['soft yellow', {
            name: 'soft yellow',
            hexString: 'ECE1BC',
            rgbs: { r: 0.9254901961, g: 0.8823529412, b: 0.737254902 },
            friends: ['cyan', 'green', 'bright orange', 'purple']
        }],
        ['bright orange', {
            name: 'bright orange',
            hexString: 'EB602B',
            rgbs: { r: 0.9215686275, g: 0.3764705882, b: 0.168627451 },
            friends: ['violet', 'soft yellow', 'pink', 'purple']
        }],
        ['cyan', {
            name: 'cyan',
            hexString: '3BE8FF',
            rgbs: { r: 0.231372549, g: 0.9098039216, b: 1 },
            friends: ['violet', 'bright orange', 'orange', 'soft yellow']
        }]
    ])
    // -------------------------------------------------------------

    // -------------------------------------------------------------
    const colorThemes: Array<{ colorNames: Array<string> }> = [
        { colorNames: ['violet', 'yellow', 'bright orange'] },
        { colorNames: ['green', 'soft yellow', 'bright orange'] },
        { colorNames: ['orange', 'pink', 'bright orange'] }
        // { colorNames: ['orange', 'violet', 'yellow'] },
        // { colorNames: ['soft yellow', 'cyan', 'yellow'] }
    ]

    export interface ColorTheme { 
        name: string;
        colors: Array<ColorData>;
    }
    export function getRandomColorTheme(): ColorTheme {
        const index = Math.floor(Math.random() * colorThemes.length)
        const themeStrings = colorThemes[index]
        
        let themeName = ''
        const theme = new Array<ColorData>()
        themeStrings.colorNames.forEach(colStr => {
            const colDat = colors.get(colStr)
            if (colDat == null) { return }
            theme.push(colDat)
            themeName += "[" + colDat.name + "] "
        })
        return { name: themeName.trim(), colors: theme }
    }   

    // -------------------------------------------------------------
}