import Vue from 'vue'
import App from './app.vue'

import isMobile from "is-mobile"

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

Vue.config.productionTip = false

let vhMax = 0
let vhMin = 999999

const isMob = isMobile()

// ========================================== //

function domContentLoaded() {
  // console.log('domContentLoaded !!!')
  // console.log(window)
  document.body.classList.add('memu-finished-loading')
}



function windowWasResized() {
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const vh = window.innerHeight * 0.01
  vhMax = isMob ? Math.max(vh, vhMax) : vh
  vhMin = isMob ? Math.min(vh, vhMin) : vh

  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vh-max', `${vhMax}px`)
  document.documentElement.style.setProperty('--vh-min', `${vhMin}px`)
  // document.documentElement.style.setProperty('--vh-rounded', `${Math.round(vh)}px`)
}

// function windowFocusOccurred() {

// }

// function windowBlurOccurred() {
  
// }

// ========================================== //

// eslint-disable-next-line
function vueCreated(vue: Vue) {
  windowWasResized()
  window.addEventListener('resize', windowWasResized)
  // window.addEventListener('focus', windowFocusOccurred)
  // window.addEventListener('blur', windowBlurOccurred)

  document.addEventListener('DOMContentLoaded', domContentLoaded)
}

// ========================================== //

new Vue({
  render: h => h(App),
  created: function () { vueCreated(this) }
}).$mount('#app')

