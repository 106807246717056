import { default as defaultChannel } from './DefaultChannel'
// import { default as lofiChannel } from './LoFiChannel'
import { default as focusChannel } from './FocusChannel'
import { default as sleepChannel } from './SleepChannel'
import { default as bestOfChannel } from './BestOfChannel'
import { default as private01Channel } from './Private01Channel'
import { default as private02Channel } from './Private02Channel'
// import { default as kpmgChannel } from './KPMGChannel'

const channels = [
    defaultChannel, // LOFI
    // lofiChannel,
    focusChannel,
    sleepChannel,
    bestOfChannel,
    private01Channel,
    private02Channel
]

export default channels